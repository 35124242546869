import * as Sentry from '@sentry/nextjs';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Router from 'next/router';

import BridgeApiConfig from '../config/bridge-api';
import store from '../redux';
import * as MeAction from '../redux/Me/action';
import * as LoadingAction from '../redux/UI/Loading/action';
import * as NotificationAction from '../redux/UI/Notification/action';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = true;

type HTTP_METHOD = 'GET' | 'POST' | 'PUT' | 'DELETE';

const DEFAULT_VERSION = '2';

const startRequest = () => {
  store.dispatch(LoadingAction.showLoading());
};

const endRequest = () => {
  store.dispatch(LoadingAction.hideLoading());
};

const pleaseLogin = () => {
  store.dispatch(
    NotificationAction.showNotificationError({
      message: 'ログインしてください',
    }),
  );
  Router.push('/auth/login');
};

const request = async (method: HTTP_METHOD, options: AxiosRequestConfig, isLoading = true, apiVersion?: string) => {
  let response: AxiosResponse;
  if (isLoading) {
    startRequest();
  }
  try {
    store.dispatch(MeAction.checkAuth());
    const authToken = store.getState().me.authToken;
    response = await axios({
      ...options,
      headers: {
        Authorization: authToken ? `Bearer ${authToken}` : undefined,
        'x-api-version': apiVersion || DEFAULT_VERSION,
      },
      method,
      url: `${BridgeApiConfig.url}${options.url}`,
      data: options.data,
      params: options.params,
    });
  } catch (err) {
    Sentry.captureException(err);
    if (err.response?.status === 401) {
      pleaseLogin();
    }
    Sentry.captureException(err);
    throw err;
  } finally {
    if (isLoading) {
      endRequest();
    }
  }

  return response;
};

export interface ApiResponse<ResponseData> {
  status: number;
  data: ResponseData;
}

export const get = async (url: string, query?: FixType, isLoading = true) => {
  return request('GET', { url, params: query }, isLoading);
};

export const post = async (url: string, data?: FixType, option: AxiosRequestConfig = {}, isLoading = true) => {
  return request('POST', { url, data, ...option }, isLoading);
};

export const put = async (url: string, data?: FixType, option: AxiosRequestConfig = {}, isLoading = true) => {
  return request('PUT', { url, data, ...option }, isLoading);
};

export const del = async (url: string, data?: FixType, isLoading = true) => {
  return request('DELETE', { url, data }, isLoading);
};

export const postWithForm = async (
  url: string,
  formData: FormData,
  options: AxiosRequestConfig = {},
  isLoading = true,
) => {
  if (isLoading) {
    startRequest();
  }
  let response: AxiosResponse;
  try {
    store.dispatch(MeAction.checkAuth());
    const authToken = store.getState().me.authToken;
    const config: AxiosRequestConfig = {
      ...options,
      headers: {
        Authorization: authToken ? `Bearer ${authToken}` : undefined,
        'Content-Type': 'multipart/form-data',
        timeout: 3600 * 1000 * 5,
      },
    };
    response = await axios.post(`${BridgeApiConfig.url}${url}`, formData, config);
  } catch (err) {
    Sentry.captureException(err);
    if (err.response?.status === 401) {
      pleaseLogin();
    }
    throw err;
  } finally {
    if (isLoading) {
      endRequest();
    }
  }

  return response;
};
