import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import * as React from 'react';

import style from './style.module.css';

const Loading = ({ isLoading }: { isLoading: boolean }) => (
  <div>
    {isLoading && (
      <div className={style.loadingBox}>
        <Box display="flex" width={'100vw'} height={'80vh'}>
          <Box m="auto">
            <CircularProgress />
          </Box>
        </Box>
      </div>
    )}
  </div>
);

export default Loading;
