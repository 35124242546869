import ROUTE from './route';

const SHORTCUT_MENU = {
  permission: 'SHORTCUT_MENU',
  item: [
    ROUTE.SHIPPING.ORDER.LIST.permission,
    ROUTE.SHIPPING.ALLOCATION.permission,
    ROUTE.SHIPPING.CLOSE.permission,
    ROUTE.ITEM.HISTORY.permission,
  ],
};

export default SHORTCUT_MENU;
