import * as ConfirmDialogAction from './action';

export interface ConfirmDialogState {
  open: boolean;
  message: string;
  openAfterSubmit?: boolean;
  submitButtonLabel?: string;
  closeButtonLabel?: string;
  onSubmit?: () => Promise<void>;
  afterClose?: () => Promise<void>;
}

const defaultState = {
  open: false,
  message: '',
};

const ConfirmDialogReducer = (state = defaultState, action: FixType) => {
  switch (action.type) {
    case ConfirmDialogAction.OPEN_CONFIRM_DIALOG:
      return { ...action.payload, open: true };

    case ConfirmDialogAction.CLOSE_CONFIRM_DIALOG:
      return { ...state, open: false };

    case ConfirmDialogAction.UPDATE_CONFIRM_DIALOG:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default ConfirmDialogReducer;
