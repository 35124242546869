import * as NotificationAction from './action';

export interface NotificationState {
  show: boolean;
  messageBarType: 'info' | 'success' | 'warning' | 'error';
  message: string | string[];
  keep?: boolean;
}

const defaultState = {
  show: false,
  message: '',
};

const NotificationReducer = (state = defaultState, action: FixType) => {
  switch (action.type) {
    case NotificationAction.SHOW_NOTIFICATION_INFO:
      return {
        ...state,
        ...action.payload,
        keep: !!action.payload.keep,
        show: true,
        messageBarType: 'info',
      };
    case NotificationAction.SHOW_NOTIFICATION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        keep: !!action.payload.keep,
        show: true,
        messageBarType: 'success',
      };
    case NotificationAction.SHOW_NOTIFICATION_WARNING:
      return {
        ...state,
        ...action.payload,
        keep: !!action.payload.keep,
        show: true,
        messageBarType: 'warning',
      };
    case NotificationAction.SHOW_NOTIFICATION_ERROR:
      return {
        ...state,
        ...action.payload,
        keep: !!action.payload.keep,
        show: true,
        messageBarType: 'error',
      };
    case NotificationAction.CLOSE_NOTIFICATION:
      return { ...state, ...action.payload, show: false };
    default:
      return state;
  }
};

export default NotificationReducer;
