export const OPEN_DRAWER_MENU = Symbol('OPEN_DRAWER_MENU');
export const CLOSE_DRAWER_MENU = Symbol('CLOSE_DRAWER_MENU');

export const openDrawerMenu = () => ({
  type: OPEN_DRAWER_MENU,
});

export const closeDrawerMenu = () => ({
  type: CLOSE_DRAWER_MENU,
});
