import { io, Socket } from 'socket.io-client';

const socket = io(process.env.NEXT_PUBLIC_BASE_URL, { transports: ['websocket'] });

const connectSocket = (): Socket => {
  if (!socket.connected) {
    socket.connect();
  }

  return socket;
};

const disConnectSocket = (): Socket => {
  socket.disconnect();

  return socket;
};

export { socket, connectSocket, disConnectSocket };
