export const SHOW_LOADING = Symbol('SHOW_LOADING');
export const HIDE_LOADING = Symbol('HIDE_LOADING');

export const showLoading = () => ({
  type: SHOW_LOADING,
});

export const hideLoading = () => ({
  type: HIDE_LOADING,
});
