export const LOGIN = Symbol('LOGIN');
export const LOGOUT = Symbol('LOGOUT');
export const LOGGED_IN = Symbol('LOGGED_IN');
export const LOGGED_OUT = Symbol('LOGGED_OUT');
export const CHECK_AUTH = Symbol('CHECK_AUTH');

export const login = (payload: FixType) => ({
  type: LOGIN,
  payload,
});

export const logout = () => ({
  type: LOGOUT,
});

export const loggedIn = (payload: FixType) => ({
  type: LOGGED_IN,
  payload,
});

export const loggedOut = () => ({
  type: LOGGED_OUT,
});

export const checkAuth = () => ({
  type: CHECK_AUTH,
});
