import { createMuiTheme } from '@material-ui/core/styles';

// @see https://material-ui.com/customization/theming/

export const mainColor = '#0098d1';

export const bridgeMuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: mainColor,
    },
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
    MuiTable: {
      size: 'small',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Noto Sans JP', 'Noto Sans CJK JP', 'sans-serif'].join(','),
    fontWeightMedium: 600,
  },
  overrides: {
    MuiTableCell: {
      footer: {
        left: 0,
        bottom: 0,
        zIndex: 2,
        position: 'sticky',
      },
    },
  },
});
