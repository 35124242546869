export const SHOW_NOTIFICATION_ERROR = Symbol('SHOW_NOTIFICATION_ERROR');
export const SHOW_NOTIFICATION_INFO = Symbol('SHOW_NOTIFICATION_INFO');
export const SHOW_NOTIFICATION_SUCCESS = Symbol('SHOW_NOTIFICATION_SUCCESS');
export const SHOW_NOTIFICATION_WARNING = Symbol('SHOW_NOTIFICATION_WARNING');
export const CLOSE_NOTIFICATION = Symbol('CLOSE_NOTIFICATION');

export const showNotificationError = (payload: { message: string; keep?: boolean }) => ({
  type: SHOW_NOTIFICATION_ERROR,
  payload,
});

export const showNotificationInfo = (payload: { message: string; keep?: boolean }) => ({
  type: SHOW_NOTIFICATION_INFO,
  payload,
});

export const showNotificationSuccess = (payload: { message: string; keep?: boolean }) => ({
  type: SHOW_NOTIFICATION_SUCCESS,
  payload,
});

export const showNotificationWarning = (payload: { message: string; keep?: boolean }) => ({
  type: SHOW_NOTIFICATION_WARNING,
  payload,
});

export const closeNotification = () => ({
  type: CLOSE_NOTIFICATION,
});
