import path from 'path';

const ROUTE = {
  ARRIVAL: {
    LIST: {
      label: '入荷管理',
      url: '/arrival/list',
      permission: 'ARRIVAL_LIST_PAGE',
    },
  },
  ARRIVAL_RECORD: {
    LIST: {
      label: '着荷予定',
      url: '/arrival-record/list',
      permission: 'ARRIVAL_RECORD_LIST_PAGE',
    },
    DETAIL: {
      permission: 'ARRIVAL_RECORD_DETAIL_PAGE',
    },
  },
  AUTH: {
    LOGIN: {
      label: 'ログイン',
      url: '/auth/login',
    },
  },
  ITEM_SKU: {
    LIST: {
      label: '商品マスタ',
      url: '/item-sku/list',
      permission: 'ITEM_SKU_LIST_PAGE',
    },
  },
  ITEM: {
    BUNDLE: {
      LIST: {
        label: '同梱物管理',
        url: '/item/bundle/list',
        permission: 'ITEM_BUNDLE_LIST_PAGE',
      },
    },
    INVENTORY: {
      ADJUSTMENT: {
        label: '棚卸調整',
        url: '/item/inventory/adjustment',
        permission: 'ITEM_INVENTORY_ADJUSTMENT_PAGE',
      },
      LIST_BASED: {
        label: '再棚卸',
        url: '/item/inventory/list-based',
        permission: 'ITEM_INVENTORY_LIST_BASED_PAGE',
      },
    },
    HISTORY: {
      label: '個品履歴',
      url: '/item/history',
      permission: 'ITEM_HISTORY_PAGE',
    },
    LIST: {
      label: '在庫一覧',
      url: '/item/list',
      permission: 'ITEM_LIST_PAGE',
    },
    CREATE: {
      label: '新規追加',
      url: '/item/create',
      permission: 'ITEM_CREATE_PAGE',
    },
    UPDATE: {
      label: 'Item Update',
      url: '/item/update',
    },
    SYSTEM_LOCATION_MAINTENANCE: {
      label: 'システムロケーション対応ロケメンテ',
      url: '/item/system-location-maintenance',
      permission: 'ITEM_SYSTEM_LOCATION_MAINTENANCE_PAGE',
    },
    LOCATION_MOVE: {
      label: '庫内移動',
      url: '/move-in-stock',
      permission: 'MOVE_IN_STOCK_PAGE',
      DETAIL: {
        permission: 'MOVE_IN_STOCK_DETAIL_PAGE',
      },
    },
    EDIT: {
      permission: 'ITEM_EDIT_PAGE',
    },
  },
  SHIPPING: {
    ORDER: {
      LIST: {
        label: '受注一覧',
        url: '/shipping/order/list',
        permission: 'SHIPPING_ORDER_LIST_PAGE',
      },
      DETAIL: {
        label: '出荷指示内容修正',
        getUrl: (shippingOrderCode: string) => path.join('/shipping/order', shippingOrderCode, 'edit'),
      },
      EDIT: {
        permission: 'SHIPPING_ORDER_EDIT_PAGE',
      },
      REGISTER: {
        label: '受注登録',
        url: '/shipping/order/register',
        permission: 'SHIPPING_ORDER_REGISTER_PAGE',
      },
    },
    ALLOCATION: {
      label: '出荷引当',
      url: '/shipping/allocation',
      permission: 'SHIPPING_ALLOCATION_PAGE',
    },
    CLOSE: {
      label: '出荷締め',
      url: '/shipping/close',
      permission: 'SHIPPING_CLOSE_PAGE',
    },
    WORK_PROGRESS: {
      label: '出荷作業進捗',
      url: '/shipping/work-progress',
      permission: 'SHIPPING_WORK_PROGRESS_PAGE',
    },
    PICKING_ITEM_LIST: {
      label: 'ピック個品',
      url: '/shipping/picking-item-list',
      permission: 'SHIPPING_PICKING_ITEM_LIST_PAGE',
    },
  },
  TOP: {
    label: 'TOP',
    url: '/top',
  },
  MY_PAGE: {
    label: 'マイページ',
    url: '/mypage',
  },
  RECEIVE: {
    RETURN: {
      label: '返却荷受',
      url: '/receive/return',
      permission: 'RECEIVE_RETURN_PAGE',
      DETAIL: {
        permission: 'RECEIVE_RETURN_DETAIL_PAGE',
      },
    },
    RETURN_LIST: {
      label: '実績一覧',
      url: '/receive/return/list',
      permission: 'RECEIVE_RETURN_LIST_PAGE',
    },
  },
  MASTER: {
    label: 'マスタ',
    url: '/master',
    permission: 'MASTER_PAGE',
  },
  SORTING_CODE: {
    label: '仕分けコード',
    url: '/sorting-code',
    permission: 'SORTING_CODE_PAGE',
  },
  EXTERNAL_LINKAGE: {
    label: '外部連携',
    url: '/external-linkage',
    permission: 'EXTERNAL_LINKAGE_PAGE',
  },
  MASTER_TABLE: {
    label: 'マスターテーブル',
    url: '/master-table',
    permission: 'MASTER_TABLE_PAGE',
  },
  USER_MANAGEMENT: {
    label: 'ユーザー管理',
    url: '/user-management',
  },
};

export default ROUTE;
