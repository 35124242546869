import * as AlertDialogAction from './action';

export interface AlertDialogState {
  open: boolean;
  alertType: AlertDialogAction.TAlertType;
  message: string | string[];
  afterClose?: () => Promise<void>;
  isBackdropClosed?: boolean;
}

const defaultState = {
  open: false,
  message: '',
};

const AlertDialogReducer = (state = defaultState, action: FixType) => {
  switch (action.type) {
    case AlertDialogAction.OPEN_ALERT_DIALOG_ERROR:
      return {
        ...state,
        ...action.payload,
        open: true,
        alertType: 'error',
      };
    case AlertDialogAction.CLOSE_ALERT_DIALOG:
      return { ...state, ...action.payload, open: false };
    default:
      return state;
  }
};

export default AlertDialogReducer;
