export const INIT_RECEIVE_RETURN_SOCKET = Symbol('INIT_RECEIVE_RETURN_SOCKET');
export const INIT_REQUEST_ANIMATION_FRAME = Symbol('INIT_REQUEST_ANIMATION_FRAME');
export const FINAL_REQUEST_ANIMATION_FRAME = Symbol('FINAL_REQUEST_ANIMATION_FRAME');
export const WATCH_ON_SOCKET_RECEIVE_RETURN = Symbol('WATCH_ON_SOCKET_RECEIVE_RETURN');
export const SET_IS_OPEN_RECEIVE_RETURN = Symbol('SET_IS_OPEN_RECEIVE_RETURN');
export const SET_IS_REFERENCING = Symbol('SET_IS_REFERENCING');
export const SET_IS_WARNING = Symbol('SET_IS_WARNING');
export const SET_RECEIVING_MODE = Symbol('SET_RECEIVING_MODE');
export const SET_CHECKING_BY = Symbol('SET_CHECKING_BY');
export const SET_COMPLETE_MODE = Symbol('SET_COMPLETE_MODE');

export const RECEIVE_RFID = Symbol('RECEIVE_RFID');
export const SET_READING_RFID = Symbol('SET_READING_RFID');
export const ADD_READING_RFID = Symbol('ADD_READING_RFID');
export const SET_READ_RFID = Symbol('SET_READ_RFID');
export const SET_HAVE_READ_RFIDS = Symbol('SET_HAVE_READ_RFIDS');
export const ADD_HAVE_READ_RFID = Symbol('ADD_HAVE_READ_RFID');
export const COMPLETE_RECEIVE_RETURN = Symbol('COMPLETE_RECEIVE_RETURN');

export const initReceiveReturnSocket = () => ({
  type: INIT_RECEIVE_RETURN_SOCKET,
});

export const initRequestAnimationFrame = () => ({
  type: INIT_REQUEST_ANIMATION_FRAME,
});

export const finalRequestAnimationFrame = () => ({
  type: FINAL_REQUEST_ANIMATION_FRAME,
});

export const watchOnSocketReceiveReturn = (payload) => ({
  type: WATCH_ON_SOCKET_RECEIVE_RETURN,
  payload,
});

export const setIsOpenReceiveReturn = (payload: { isOpenReceiveReturn: boolean }) => ({
  type: SET_IS_OPEN_RECEIVE_RETURN,
  payload,
});

export const setIsReferencing = (payload: { isReferencing: boolean }) => ({
  type: SET_IS_REFERENCING,
  payload,
});

export const setIsWarning = (payload: { isWarning: boolean }) => ({
  type: SET_IS_WARNING,
  payload,
});

export const receiveRfid = (payload: { rfid: string; type: string }) => ({
  type: RECEIVE_RFID,
  payload,
});

export const setReadingRFIDs = (payload: { rfids: string[] }) => ({
  type: SET_READING_RFID,
  payload,
});

export const addReadingRFIDs = (payload: { rfid: string }) => ({
  type: ADD_READING_RFID,
  payload,
});

export const setReadRFIDs = (payload: { rfids: string[] }) => ({
  type: SET_READ_RFID,
  payload,
});

export const setHaveReadRFIDs = (payload: { rfids: string[] }) => ({
  type: SET_HAVE_READ_RFIDS,
  payload,
});

export const addHaveReadRFID = (payload: { rfid: string }) => ({
  type: ADD_HAVE_READ_RFID,
  payload,
});

export const setCheckingBy = (payload: { checkingBy: string }) => ({
  type: SET_CHECKING_BY,
  payload,
});

export const setCompleteMode = (payload: { completeMode: string }) => ({
  type: SET_COMPLETE_MODE,
  payload,
});
