import { all, put, take, takeEvery } from 'redux-saga/effects';

import {
  CLOSE_CONFIRM_DIALOG,
  OPEN_CONFIRM_DIALOGS,
  openConfirmDialog,
  openConfirmDialogs,
} from './ConfirmDialog/action';

function* handleOpenConfirmDialogs(action: ReturnType<typeof openConfirmDialogs>) {
  const { dialogDataList, openAfterSubmit, onSubmit } = action.payload;

  for (const infoKey in dialogDataList) {
    const data = dialogDataList[infoKey];
    const isLastConfirm = parseInt(infoKey) === dialogDataList.length - 1;
    const _openAfterSubmit = isLastConfirm ? openAfterSubmit : undefined;
    const _onSubmit = isLastConfirm ? onSubmit : () => undefined;

    yield put(
      openConfirmDialog({
        message: data.message,
        submitButtonLabel: data.submitButtonLabel,
        closeButtonLabel: data.closeButtonLabel,
        openAfterSubmit: _openAfterSubmit,
        onSubmit: _onSubmit,
      }),
    );

    if (!isLastConfirm) {
      yield take(CLOSE_CONFIRM_DIALOG);
    }
  }
}

function* rootSaga() {
  yield all([takeEvery(OPEN_CONFIRM_DIALOGS, handleOpenConfirmDialogs)]);
}

export default rootSaga;
