import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

type SentryEnvironment = 'production' | 'staging' | 'development';

const defaultConfig = {
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT || 'development',
  release: process.env.SENTRY_RELEASE || 'development', // BRANCHはnetlifyから取得可能
  tracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE,
};

export const createSentryConfig = (): Sentry.NodeOptions => {
  const { tracesSampleRate, ...rest } = defaultConfig;

  const config: Sentry.NodeOptions = {
    ...rest,
    integrations: [new BrowserTracing()],
  };

  if ((config.environment as SentryEnvironment) !== 'production') {
    config.debug = true;
  }

  if (tracesSampleRate && parseFloat(tracesSampleRate)) {
    config.tracesSampleRate = parseFloat(tracesSampleRate);
  }

  return config;
};
