import { all, fork } from 'redux-saga/effects';

import MeSaga from './Me/saga';
import ReceiveReturnSaga from './ReceiveReturn/saga';
import ReceiveReturnSocketSaga from './ReceiveReturn/socket';
import UISaga from './UI/saga';

export function* rootSaga() {
  yield all([fork(MeSaga), fork(UISaga), fork(ReceiveReturnSaga), fork(ReceiveReturnSocketSaga)]);
}
