export const OPEN_ALERT_DIALOG_ERROR = Symbol('OPEN_ALERT_DIALOG_ERROR');
export const CLOSE_ALERT_DIALOG = Symbol('CLOSE_ALERT_DIALOG');

export type TAlertType = 'info' | 'success' | 'warning' | 'error';

export interface AlertDialogPayload {
  message: string | string[];
  afterClose?: () => Promise<void>;
  isBackdropClosed?: boolean;
}

export const openAlertDialogError = (payload: AlertDialogPayload) => ({
  type: OPEN_ALERT_DIALOG_ERROR,
  payload,
});

export const closeAlertDialog = () => ({
  type: CLOSE_ALERT_DIALOG,
});
