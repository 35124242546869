import Me from '../../model/Me';
import * as MeAction from './action';

export type meStore = Me;

const initialState = { isLoggedIn: false };

const MeReducer = (state = initialState, action: FixType) => {
  switch (action.type) {
    case MeAction.LOGGED_IN: {
      return {
        isLoggedIn: true,
        ...action.payload,
      };
    }
    case MeAction.LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default MeReducer;
