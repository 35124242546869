import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import * as React from 'react';

import { ConfirmDialogState } from '../../redux/UI/ConfirmDialog/reducer';
import AttentionMark from './AttentionMark';
import styles from './style.module.css';

interface Props extends ConfirmDialogState {
  openAfterSubmit?: boolean;
  onClose: () => void;
}

export default function ConfirmDialog(props: Props) {
  const { open, message, submitButtonLabel, closeButtonLabel, openAfterSubmit, onSubmit, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose} className={styles.container}>
      <div className={styles.attentionArea}>
        <AttentionMark className={styles.attentionMark} />
        <div className={styles.attentionMessage}>Attention!</div>
      </div>
      <DialogContent className={styles.contentArea}>{message}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          {closeButtonLabel || '閉じる'}
        </Button>
        {submitButtonLabel && (
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              await onSubmit();
              if (!openAfterSubmit) {
                onClose();
              }
            }}
          >
            {submitButtonLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
