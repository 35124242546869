import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Alert from '@material-ui/lab/Alert';
import * as React from 'react';

import { AlertDialogState } from '../../redux/UI/AlertDialog/reducer';
import styles from './style.module.css';

interface Props extends AlertDialogState {
  onClose: () => void;
}

const useDialogStyles = makeStyles(() => ({
  container: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  paper: {
    backgroundColor: 'unset',
    boxShadow: 'unset',
    margin: 0,
  },
}));

const useAlertStyles = makeStyles(() => ({
  root: {
    borderRadius: 0,
    alignItems: 'center',
  },
  message: {
    fontSize: '20px',
    color: 'rgba(254, 2, 23, 0.88)',
  },
  standardError: {
    backgroundColor: '#FFE9E9',
  },
}));

const useAlertIconStyles = makeStyles(() => ({
  root: {
    width: '60px',
    height: '48px',
  },
}));

export default function AlertDialog(props: Props) {
  const { open, message, onClose, isBackdropClosed, alertType } = props;

  const dialogClasses = useDialogStyles();
  const alertClasses = useAlertStyles();
  const alertIconClasses = useAlertIconStyles();

  const onClickBackdrop = () => {
    if (isBackdropClosed) {
      onClose();
    }
  };

  return (
    <Dialog open={open} classes={dialogClasses} className={styles.container} onClose={onClickBackdrop} maxWidth="md">
      <DialogActions>
        <Button variant="contained" onClick={onClose} className={`${styles.closeButton} ${styles[alertType]}`}>
          <CloseIcon />
        </Button>
      </DialogActions>
      <DialogContent className={styles.contentArea}>
        <Alert
          severity={alertType}
          classes={alertClasses}
          icon={<ReportProblemOutlinedIcon fontSize="large" classes={alertIconClasses} />}
        >
          {message}
        </Alert>
      </DialogContent>
    </Dialog>
  );
}
