import { Collapse, Menu, MenuItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MenuIcon from '@material-ui/icons/Menu';
import PublishIcon from '@material-ui/icons/Publish';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import SettingsIcon from '@material-ui/icons/Settings';
import clsx from 'clsx';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';

import { Can } from '../../Can';
import logoTextImage from '../../asset/img/logo-text';
import DRAWER_MENU_TEXT from '../../constants/drawer-menu-text';
import ROUTE from '../../constants/route';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    height: `40px`,
  },
  toolbar2: {
    minHeight: `40px`, // minHeight を再設定する
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nestedFour: {
    paddingLeft: theme.spacing(1.8),
  },
  nestedFourOpen: {
    paddingLeft: theme.spacing(4),
  },
  drawerItemText: {},
  drawerOpenItemText: {
    fontSize: '0.9rem',
  },
  drawerCloseItemText: {
    fontSize: '0.7rem',
  },
  drawerActiveItem: {
    backgroundColor: '#f1f1f1',
  },
  grow: {
    flexGrow: 1,
  },
  sectionAbout: {
    display: 'none',
  },
  logoImgContainer: {
    paddingTop: theme.spacing(0.5),
  },
  img: {
    display: 'block',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}));

export default function MiniDrawer(props) {
  const { isOpen, open, close, logout } = props;

  const classes = useStyles();
  const theme = useTheme();
  const router = useRouter();

  const handleDrawerOpen = () => {
    open();
  };
  const handleDrawerClose = () => {
    close();
  };
  const onClickMenuItem = useCallback(() => {
    close();
  }, []);
  const [activeItemMenus, setActiveItemMenus] = React.useState(false);
  const onClickItemMenuRoot = () => {
    setActiveItemMenus(!activeItemMenus);
    setActiveShippingMenus(false);
    setActiveArrivalMenus(false);
    setActiveReceiveReturnMenus(false);
  };
  const [activeShippingMenus, setActiveShippingMenus] = React.useState(false);
  const onClickShippingMenuRoot = () => {
    setActiveShippingMenus(!activeShippingMenus);
    setActiveItemMenus(false);
    setActiveArrivalMenus(false);
    setActiveReceiveReturnMenus(false);
  };
  const [activeArrivalMenus, setActiveArrivalMenus] = React.useState(false);
  const onClickArrivalMenuRoot = () => {
    setActiveArrivalMenus(!activeArrivalMenus);
    setActiveShippingMenus(false);
    setActiveItemMenus(false);
    setActiveReceiveReturnMenus(false);
  };

  const [activeReceiveReturnMenus, setActiveReceiveReturnMenus] = React.useState(false);
  const onClickReceiveMenuRoot = () => {
    setActiveReceiveReturnMenus(!activeReceiveReturnMenus);
    setActiveArrivalMenus(false);
    setActiveShippingMenus(false);
    setActiveItemMenus(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // どのメニューグループに一致するか
    const found = Object.values(DRAWER_MENU_TEXT).find((elem) => {
      // Nestしているので、子のpathと現在のurlが一致しているかを検索&検索
      return elem?.items && elem?.items.find((item) => item.path === router.pathname);
    });
    switch (found?.path) {
      case DRAWER_MENU_TEXT.ITEM_MENU.path:
        onClickItemMenuRoot();
        break;
      case DRAWER_MENU_TEXT.SHIPPING_MENU.path:
        onClickShippingMenuRoot();
        break;
      case DRAWER_MENU_TEXT.ARRIVAL_MENU.path:
        onClickArrivalMenuRoot();
        break;
      case DRAWER_MENU_TEXT.RECEIVE_MENU.path:
        onClickReceiveMenuRoot();
        break;
      default:
        return;
    }
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div className={classes.grow}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: isOpen,
          })}
          style={{ flexDirection: 'row' }}
        >
          <Toolbar className={classes.toolbar2}>
            <Tooltip title="メニューを開く">
              <span>
                <IconButton
                  color="inherit"
                  aria-label="isOpen drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: isOpen,
                  })}
                >
                  <MenuIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Link href="/top" passHref>
              <a className={classes.logoImgContainer}>
                <img className={classes.img} src={logoTextImage} width={88} height={26} />
              </a>
            </Link>
          </Toolbar>
          <div className={classes.grow} />
          <Box alignItems="center" mr={1}>
            <IconButton
              onClick={handleProfileMenuOpen}
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
          </Box>
        </AppBar>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => Router.push(ROUTE.MY_PAGE.url)}>マイページ</MenuItem>
          <MenuItem onClick={logout}>ログアウト</MenuItem>
        </Menu>
      </div>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {/** 在庫 */}
          <Can I={'access'} a={DRAWER_MENU_TEXT.ITEM_MENU.permission}>
            <ListItem button key={DRAWER_MENU_TEXT.ITEM_MENU.key} onClick={onClickItemMenuRoot}>
              <ListItemIcon>
                <HomeWorkIcon />
              </ListItemIcon>
              <ListItemText primary={DRAWER_MENU_TEXT.ITEM_MENU.value} />
              {activeItemMenus ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={activeItemMenus} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {DRAWER_MENU_TEXT.ITEM_MENU.items.map((item) => (
                  <Can I={'access'} a={item.permission} key={item.path}>
                    <Link href={item.path} passHref>
                      <a>
                        <ListItem
                          button
                          // onClick={onClickMenuItem}
                          className={clsx(classes.nestedFour, {
                            [classes.nestedFourOpen]: isOpen,
                            [classes.drawerActiveItem]: item.path === router.pathname,
                          })}
                        >
                          <Typography
                            className={clsx({
                              [classes.drawerOpenItemText]: isOpen,
                              [classes.drawerCloseItemText]: !isOpen,
                            })}
                          >
                            {item.value}
                          </Typography>
                        </ListItem>
                      </a>
                    </Link>
                  </Can>
                ))}
              </List>
            </Collapse>
          </Can>
          {/** 出荷 */}
          <Can I={'access'} a={DRAWER_MENU_TEXT.SHIPPING_MENU.permission}>
            <ListItem button key={DRAWER_MENU_TEXT.SHIPPING_MENU.key} onClick={onClickShippingMenuRoot}>
              <ListItemIcon>
                <LocalShippingIcon />
              </ListItemIcon>
              <ListItemText primary={DRAWER_MENU_TEXT.SHIPPING_MENU.value} />
              {activeShippingMenus ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={activeShippingMenus} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {DRAWER_MENU_TEXT.SHIPPING_MENU.items.map((item) => (
                  <Can I={'access'} a={item.permission} key={item.path}>
                    <Link href={item.path} passHref>
                      <a>
                        <ListItem
                          button
                          onClick={onClickMenuItem}
                          className={clsx(classes.nestedFour, {
                            [classes.nestedFourOpen]: isOpen,
                            [classes.drawerActiveItem]: item.path === router.pathname,
                          })}
                        >
                          <Typography
                            className={clsx({
                              [classes.drawerOpenItemText]: isOpen,
                              [classes.drawerCloseItemText]: !isOpen,
                            })}
                          >
                            {item.value}
                          </Typography>
                        </ListItem>
                      </a>
                    </Link>
                  </Can>
                ))}
              </List>
            </Collapse>
          </Can>

          {/** 入荷 */}
          <Can I={'access'} a={DRAWER_MENU_TEXT.ARRIVAL_MENU.permission}>
            <ListItem button key={DRAWER_MENU_TEXT.ARRIVAL_MENU.key} onClick={onClickArrivalMenuRoot}>
              <ListItemIcon>
                <ImportExportIcon />
              </ListItemIcon>
              <ListItemText primary={DRAWER_MENU_TEXT.ARRIVAL_MENU.value} />
              {activeArrivalMenus ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={activeArrivalMenus} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {DRAWER_MENU_TEXT.ARRIVAL_MENU.items.map((item) => (
                  <Can I={'access'} a={item.permission} key={item.path}>
                    <Link href={item.path} passHref>
                      <a>
                        <ListItem
                          button
                          onClick={onClickMenuItem}
                          className={clsx(classes.nestedFour, {
                            [classes.nestedFourOpen]: isOpen,
                          })}
                        >
                          <Typography
                            className={clsx({
                              [classes.drawerOpenItemText]: isOpen,
                              [classes.drawerCloseItemText]: !isOpen,
                            })}
                          >
                            {item.value}
                          </Typography>
                        </ListItem>
                      </a>
                    </Link>
                  </Can>
                ))}
              </List>
            </Collapse>
          </Can>

          {/** 返却 */}
          <Can I={'access'} a={DRAWER_MENU_TEXT.RECEIVE_MENU.permission}>
            <ListItem button key={DRAWER_MENU_TEXT.RECEIVE_MENU.key} onClick={onClickReceiveMenuRoot}>
              <ListItemIcon>
                <ReplyAllIcon />
              </ListItemIcon>
              <ListItemText primary={DRAWER_MENU_TEXT.RECEIVE_MENU.value} />
              {activeReceiveReturnMenus ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={activeReceiveReturnMenus} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {DRAWER_MENU_TEXT.RECEIVE_MENU.items.map((item) => (
                  <Can I={'access'} a={item.permission} key={item.path}>
                    <Link href={item.path} passHref>
                      <a>
                        <ListItem
                          button
                          onClick={onClickMenuItem}
                          className={clsx(classes.nestedFour, {
                            [classes.nestedFourOpen]: isOpen,
                          })}
                        >
                          <Typography
                            className={clsx({
                              [classes.drawerOpenItemText]: isOpen,
                              [classes.drawerCloseItemText]: !isOpen,
                            })}
                          >
                            {item.value}
                          </Typography>
                        </ListItem>
                      </a>
                    </Link>
                  </Can>
                ))}
              </List>
            </Collapse>
          </Can>

          {/** マスタ */}
          <Can I={'access'} a={DRAWER_MENU_TEXT.MASTER_MENU.permission}>
            <Link href={DRAWER_MENU_TEXT.MASTER_MENU.path} passHref key={DRAWER_MENU_TEXT.MASTER_MENU.path}>
              <ListItem button key={DRAWER_MENU_TEXT.MASTER_MENU.key}>
                <ListItemIcon>
                  <PublishIcon />
                </ListItemIcon>
                <ListItemText primary={DRAWER_MENU_TEXT.MASTER_MENU.value} />
              </ListItem>
            </Link>
          </Can>

          {/** 外部連携 */}
          <Can I={'access'} a={DRAWER_MENU_TEXT.EXTERNAL_LINKAGE_MENU.permission}>
            <Link
              href={DRAWER_MENU_TEXT.EXTERNAL_LINKAGE_MENU.path}
              passHref
              key={DRAWER_MENU_TEXT.EXTERNAL_LINKAGE_MENU.path}
            >
              <ListItem button key={DRAWER_MENU_TEXT.EXTERNAL_LINKAGE_MENU.key}>
                <ListItemIcon>
                  <LocalGroceryStoreIcon />
                </ListItemIcon>
                <ListItemText primary={DRAWER_MENU_TEXT.EXTERNAL_LINKAGE_MENU.value} />
              </ListItem>
            </Link>
          </Can>
          {/** マスターテーブル */}
          <Can I={'access'} a={DRAWER_MENU_TEXT.MASTER_TABLE_MENU.permission}>
            <Link href={DRAWER_MENU_TEXT.MASTER_TABLE_MENU.path} passHref key={DRAWER_MENU_TEXT.MASTER_TABLE_MENU.path}>
              <ListItem button key={DRAWER_MENU_TEXT.MASTER_TABLE_MENU.key}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={DRAWER_MENU_TEXT.MASTER_TABLE_MENU.value} />
              </ListItem>
            </Link>
          </Can>
        </List>

        {/*<Divider />*/}
      </Drawer>
      <main className={classes.content}>{props.children}</main>
    </div>
  );
}
