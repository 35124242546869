import * as DrawerMenuAction from './action';

export interface DrawerMenuState {
  open: boolean;
}

const defaultState = { open: false };

const DrawerMenuReducer = (state = defaultState, action: FixType) => {
  switch (action.type) {
    case DrawerMenuAction.OPEN_DRAWER_MENU:
      return { open: true };
    case DrawerMenuAction.CLOSE_DRAWER_MENU:
      return { open: false };
    default:
      return state;
  }
};

export default DrawerMenuReducer;
