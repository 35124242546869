import { actionChannel, take, select, all, fork, put, call, cancel } from 'redux-saga/effects';

import selectors from '../selector';
import * as receiveReturnActions from './action';

const waitNextFrame = () => {
  return new Promise((resolve) => {
    requestAnimationFrame(resolve);
  });
};

function* watchAnimationFrame() {
  while (true) {
    yield call(waitNextFrame);

    const receiveReturn = yield select(selectors.receiveReturn);

    if (receiveReturn.readingRFIDs.length) {
      yield put(receiveReturnActions.setReadRFIDs({ rfids: receiveReturn.readingRFIDs }));
      yield put(receiveReturnActions.setReadingRFIDs({ rfids: [] }));
    }
  }
}

function* initReceiveReturn() {
  while (true) {
    yield take(receiveReturnActions.INIT_REQUEST_ANIMATION_FRAME);

    const watchAnimationFrameTask = yield fork(watchAnimationFrame);

    yield take(receiveReturnActions.FINAL_REQUEST_ANIMATION_FRAME);
    yield cancel(watchAnimationFrameTask);
  }
}

function* watchReceiveRfid() {
  const channel = yield actionChannel(receiveReturnActions.RECEIVE_RFID);

  while (true) {
    const {
      payload: { rfid, type },
    } = yield take(channel);

    const receiveReturn = yield select(selectors.receiveReturn);
    if (
      receiveReturn.isWarning ||
      !receiveReturn.isOpenReceiveReturn ||
      rfid === receiveReturn.readingRFID ||
      receiveReturn.haveReadRFIDs.includes(rfid)
    ) {
      continue;
    }

    if (!receiveReturn.readingRFIDs.includes(rfid)) {
      yield put(receiveReturnActions.addReadingRFIDs({ rfid }));
    }

    if (receiveReturn.checkingBy !== type) {
      yield put(receiveReturnActions.setCheckingBy({ checkingBy: type }));
    }
  }
}

function* rootSaga() {
  yield all([fork(initReceiveReturn), fork(watchReceiveRfid)]);
}

export default rootSaga;
