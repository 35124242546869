import * as LoadingAction from './action';

export interface LoadingState {
  isLoading: boolean;
}

const defaultState: LoadingState = {
  isLoading: false,
};

const LoadingReducer = (state: LoadingState = defaultState, action: FixType) => {
  switch (action.type) {
    case LoadingAction.SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case LoadingAction.HIDE_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default LoadingReducer;
