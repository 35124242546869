import Router from 'next/router';
import { all, call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import BRIDGE_API from '../../constants/bridge-api';
import * as Request from '../../util/request';
import { socket } from '../../util/socket';
import * as NotificationAction from '../UI/Notification/action';
import selectors from '../selector';
import * as MeAction from './action';
import { CHECK_AUTH } from './action';

const AUTH_CACHE_KEY = 'AUTH_CACHE_KEY';

function* login() {
  while (true) {
    const {
      payload: { email, password },
    } = yield take(MeAction.LOGIN);

    // For login response.
    let authToken = '';
    let user: FixType = {};

    try {
      const { data: response } = yield Request.post(BRIDGE_API.LOGIN, {
        email,
        password,
      });
      authToken = response.authToken;
      user = response.user;
    } catch (err) {
      if (err.response?.status === 400) {
        yield put(
          NotificationAction.showNotificationWarning({
            message: 'emailもしくはパスワードが間違っています。',
          }),
        );
      } else {
        yield put(
          NotificationAction.showNotificationError({
            message: `${err.message}`,
          }),
        );
      }

      continue;
    }

    const me = {
      authToken,
      ...user,
    };

    localStorage.setItem(AUTH_CACHE_KEY, JSON.stringify(me));

    yield put(MeAction.loggedIn(me));

    Router.push('/');
    // yield put(replace("/"));
    yield put(
      NotificationAction.showNotificationSuccess({
        message: 'ログインしました。',
      }),
    );
  }
}

function* logout() {
  while (true) {
    yield take(MeAction.LOGOUT);

    if (socket.connected) {
      const { id: userId } = yield select(selectors.me);

      socket.emit(`UnsubscribeUserId`, { userId });
    }

    yield Request.post(BRIDGE_API.LOGOUT);

    yield put(MeAction.loggedOut());
    localStorage.clear();

    Router.push('/auth/login');
    // yield put(replace('/auth/login'));
    yield put(
      NotificationAction.showNotificationSuccess({
        message: 'ログアウトしました。',
      }),
    );
  }
}

function* guestNavigate() {
  Router.push('/auth/login');
  yield put(
    NotificationAction.showNotificationInfo({
      message: 'ログインしてください。',
    }),
  );
}

function* checkAuth() {
  const { isLoggedIn } = yield select(selectors.me);

  const me = localStorage.getItem(AUTH_CACHE_KEY);

  if (!isLoggedIn) {
    if (me) {
      yield put(MeAction.loggedIn(JSON.parse(me)));
    } else {
      return yield call(guestNavigate);
    }
  }
}

function* rootSaga() {
  yield all([fork(login), fork(logout), takeEvery(CHECK_AUTH, checkAuth)]);
}

export default rootSaga;
