import ROUTE from './route';

type Item = { value: string; path: string; permission: string };
type DrawerMenu = {
  [key: string]: {
    key: string;
    value: string;
    path: string;
    items?: Item[];
    permission?: string;
  };
};

const DRAWER_MENU_TEXT: DrawerMenu = {
  ITEM_MENU: {
    key: 'item-menu',
    value: '在庫',
    path: ROUTE.ITEM.LIST.url,
    permission: 'ITEM_MENU_ELEMENT',
    items: [
      {
        value: ROUTE.ITEM_SKU.LIST.label,
        path: ROUTE.ITEM_SKU.LIST.url,
        permission: ROUTE.ITEM_SKU.LIST.permission,
      },
      {
        value: ROUTE.ITEM.LIST.label,
        path: ROUTE.ITEM.LIST.url,
        permission: ROUTE.ITEM.LIST.permission,
      },
      {
        value: ROUTE.ITEM.HISTORY.label,
        path: ROUTE.ITEM.HISTORY.url,
        permission: ROUTE.ITEM.HISTORY.permission,
      },
      {
        value: ROUTE.ITEM.BUNDLE.LIST.label,
        path: ROUTE.ITEM.BUNDLE.LIST.url,
        permission: ROUTE.ITEM.BUNDLE.LIST.permission,
      },
      {
        value: ROUTE.ITEM.INVENTORY.ADJUSTMENT.label,
        path: ROUTE.ITEM.INVENTORY.ADJUSTMENT.url,
        permission: ROUTE.ITEM.INVENTORY.ADJUSTMENT.permission,
      },
      {
        value: 'ロケメンテ',
        path: ROUTE.ITEM.SYSTEM_LOCATION_MAINTENANCE.url,
        permission: ROUTE.ITEM.SYSTEM_LOCATION_MAINTENANCE.permission,
      },
      {
        value: ROUTE.ITEM.LOCATION_MOVE.label,
        path: ROUTE.ITEM.LOCATION_MOVE.url,
        permission: ROUTE.ITEM.LOCATION_MOVE.permission,
      },
      {
        value: ROUTE.ITEM.INVENTORY.LIST_BASED.label,
        path: ROUTE.ITEM.INVENTORY.LIST_BASED.url,
        permission: ROUTE.ITEM.INVENTORY.LIST_BASED.permission,
      },
    ] as Item[],
  },
  SHIPPING_MENU: {
    key: 'shipping-menu',
    value: '出荷',
    path: ROUTE.SHIPPING.WORK_PROGRESS.url,
    permission: 'SHIPPING_MENU_ELEMENT',
    items: [
      {
        value: '作業進捗',
        path: ROUTE.SHIPPING.WORK_PROGRESS.url,
        permission: ROUTE.SHIPPING.WORK_PROGRESS.permission,
      },
      {
        value: ROUTE.SHIPPING.ORDER.LIST.label,
        path: ROUTE.SHIPPING.ORDER.LIST.url,
        permission: ROUTE.SHIPPING.ORDER.LIST.permission,
      },
      {
        value: ROUTE.SHIPPING.ORDER.REGISTER.label,
        path: ROUTE.SHIPPING.ORDER.REGISTER.url,
        permission: ROUTE.SHIPPING.ORDER.REGISTER.permission,
      },
      {
        value: ROUTE.SHIPPING.ALLOCATION.label,
        path: ROUTE.SHIPPING.ALLOCATION.url,
        permission: ROUTE.SHIPPING.ALLOCATION.permission,
      },
      {
        value: ROUTE.SHIPPING.CLOSE.label,
        path: ROUTE.SHIPPING.CLOSE.url,
        permission: ROUTE.SHIPPING.CLOSE.permission,
      },
      {
        value: ROUTE.SHIPPING.PICKING_ITEM_LIST.label,
        path: ROUTE.SHIPPING.PICKING_ITEM_LIST.url,
        permission: ROUTE.SHIPPING.PICKING_ITEM_LIST.permission,
      },
    ] as Item[],
  },
  ARRIVAL_MENU: {
    key: 'arrival-menu',
    value: '入荷',
    path: ROUTE.ARRIVAL.LIST.url,
    permission: 'ARRIVAL_MENU_ELEMENT',
    items: [
      {
        value: ROUTE.ARRIVAL.LIST.label,
        path: ROUTE.ARRIVAL.LIST.url,
        permission: ROUTE.ARRIVAL.LIST.permission,
      },
      {
        value: ROUTE.ARRIVAL_RECORD.LIST.label,
        path: ROUTE.ARRIVAL_RECORD.LIST.url,
        permission: ROUTE.ARRIVAL_RECORD.LIST.permission,
      },
    ] as Item[],
  },
  RECEIVE_MENU: {
    key: 'receive-menu',
    value: '返却',
    path: ROUTE.RECEIVE.RETURN.url,
    permission: 'RECEIVE_MENU_ELEMENT',
    items: [
      {
        value: ROUTE.RECEIVE.RETURN_LIST.label,
        path: ROUTE.RECEIVE.RETURN_LIST.url,
        permission: ROUTE.RECEIVE.RETURN_LIST.permission,
      },
      {
        value: ROUTE.RECEIVE.RETURN.label,
        path: ROUTE.RECEIVE.RETURN.url,
        permission: ROUTE.RECEIVE.RETURN.permission,
      },
    ] as Item[],
  },
  MASTER_MENU: {
    key: 'master-menu',
    value: ROUTE.MASTER.label,
    path: ROUTE.MASTER.url,
    permission: ROUTE.MASTER.permission,
  },
  EXTERNAL_LINKAGE_MENU: {
    key: 'external-linkage-menu',
    value: ROUTE.EXTERNAL_LINKAGE.label,
    path: ROUTE.EXTERNAL_LINKAGE.url,
    permission: ROUTE.EXTERNAL_LINKAGE.permission,
  },
  MASTER_TABLE_MENU: {
    key: 'master-table-menu',
    value: ROUTE.MASTER_TABLE.label,
    path: ROUTE.MASTER_TABLE.url,
    permission: ROUTE.MASTER_TABLE.permission,
  },
} as const;

export default DRAWER_MENU_TEXT;
