import DayjsUtils from '@date-io/dayjs';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Head from 'next/head';
import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import 'reflect-metadata';

import App from '../App';
import { bridgeMuiTheme } from '../bridge-mui-theme';
import store from '../redux';
import './styles/globals.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 60000,
    },
  },
});

function AppRoot({ Component, pageProps }) {
  return (
    <div>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content="noindex, nofollow" />
        <title>Bridge</title>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/earlyaccess/notosansjp.css" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap" />
      </Head>

      <MuiThemeProvider theme={bridgeMuiTheme}>
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <App>{typeof window === 'undefined' ? null : <Component {...pageProps} />}</App>
            </QueryClientProvider>
          </Provider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </div>
  );
}

export default AppRoot;
