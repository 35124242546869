import { eventChannel } from 'redux-saga';
import { take, fork, select, join, put, call, all } from 'redux-saga/effects';

import { connectSocket } from '../../util/socket';
import * as meAction from '../Me/action';
import * as receiveReturnActions from '../ReceiveReturn/action';
import selectors from '../selector';

const socket = connectSocket();

const AUTH_CACHE_KEY = 'AUTH_CACHE_KEY';

function* initSocket() {
  let isInitial = true;

  while (true) {
    yield take(receiveReturnActions.INIT_RECEIVE_RETURN_SOCKET);

    const waitLoggedInTask = yield fork(waitLoggedIn);
    const userId = yield join(waitLoggedInTask);

    if (isInitial) {
      socket.on('connect', function () {
        subscribeUserid(userId);
      });
      isInitial = false;
    }

    yield call(subscribeUserid, userId);

    yield put(receiveReturnActions.watchOnSocketReceiveReturn({ userId }));
  }
}

function* waitLoggedIn() {
  const { isLoggedIn } = yield select(selectors.me);

  if (!isLoggedIn) {
    yield take(meAction.LOGGED_IN);
  }

  const me = JSON.parse(localStorage.getItem(AUTH_CACHE_KEY));

  return me.id;
}

function* watchOnSocket() {
  while (true) {
    try {
      const {
        payload: { userId },
      } = yield take(receiveReturnActions.WATCH_ON_SOCKET_RECEIVE_RETURN);

      yield fork(subscribeSocket, userId);
    } catch (err) {
      throw new Error(err);
    }
  }
}

function* subscribeSocket(userId) {
  const channel = yield call(subscribe, userId);

  while (true) {
    const action = yield take(channel);

    yield put(action);
  }
}

function subscribe(userId) {
  return eventChannel((emit) => {
    const receiveRFIDFromServer = async (payload) => {
      const { rfid, type } = payload;

      emit(receiveReturnActions.receiveRfid({ rfid, type }));
    };

    socket.on(`SendRFIDToClient:${userId}`, receiveRFIDFromServer);

    const unsubscribe = () => {
      socket.off(`SendRFIDToClient:${userId}`, receiveRFIDFromServer);
    };

    return unsubscribe;
  });
}

function subscribeUserid(userId) {
  socket.emit(`SubscribeUserId`, { userId });
}

export default function* rootSaga() {
  yield all([fork(initSocket), fork(watchOnSocket)]);
}
