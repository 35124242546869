const BRIDGE_API = {
  LOGIN: '/internal/auth/login',
  LOGOUT: '/internal/auth/logout',
  ARRIVAL_RESULT: '/internal/arrival/result',
  ARRIVAL_RESULT_CSV: '/internal/arrival/result/csv',
  ARRIVAL_RECORD_RESULT: '/internal/arrival-record/result',
  ARRIVAL_RECORD_DETAIL: '/internal/arrival-record',
  ARRIVAL_RECORD_RESULT_CSV: '/internal/arrival-record/result/csv',
  ARRIVAL_RECORD_DETAIL_RESULT: '/internal/arrival-record-detail/result',
  ARRIVAL_RECORD_DETAIL_RESULT_CSV: '/internal/arrival-record-detail/result/csv',
  ARRIVAL_RECORD_UPDATE: '/internal/arrival-record/update',
  BRAND: '/internal/brand',
  LONG_RUNNING_TASK_LAST: '/internal/long-running-task/last',
  LONG_RUNNING_TASK: '/internal/long-running-task',
  SHIPPING_ALLOCATION: '/internal/shipping/allocation',
  SHIPPING_CLOSE: '/internal/shipping/close',
  SHIPPING_CLOSE_RESULT_OUTPUT: '/internal/shipping/close/result/output',
  SHIPPING_ORDER_WAYBILL_OUTPUT: '/internal/shipping/order/waybill/output',
  SHIPPING_ORDER_CANCEL: '/internal/shipping/order/cancel',
  SHIPPING_ORDER: '/internal/shipping/order',
  SHIPPING_ORDER_LIST: '/internal/shipping/order/find',
  SHIPPING_ORDER_ITEM: '/internal/shipping/order/item',
  SHIPPING_ORDER_CSV_OUTPUT: '/internal/shipping/order/csv/output',
  SHIPPING_ORDER_DETAIL: '/internal/shipping/order',
  SHIPPING_ORDER_DETAIL_VALIDATE: '/internal/shipping/order/validate',
  SHIPPING_ORDER_CSV_IMPORT: '/internal/shipping/order/file/csv',
  SHIPPING_ORDER_CSV_FORMAT: '/internal/shipping/order/file/csv/format',
  SHIPPING_STANDBY_CLOSE_SUMMARY: '/internal/shipping/standby-close/summary',
  SHIPPING_STANDBY_ALLOCATION_SUMMARY: '/internal/shipping/standby-allocation/summary',
  SHIPPING_WORK_PROGRESS_SUMMARY: '/internal/shipping/work-progress/summary',
  AGREED_SHIPMENT: '/internal/agreed-shipment',
  SHIPPING_ORDER_STATUS: '/internal/shipping/order/status',
  SHIPPING_ORDER_ESTIMATED_SHIPPING_DATE: '/internal/shipping/order/estimated-shipping-date',
  SHIPPING_CHECK_AVAILABLE_WAYBILL_NUMBER: '/internal/shipping/available-waybill-number/check',
  SHIPPING_PURPOSE: '/internal/shipping-purpose',
  SHIPPING_ALLOCATION_SEARCH: '/internal/shipping/allocation/search',
  INVENTORY_LIST_BASED: '/internal/inventory/list-based',
  INVENTORY_RESULT_FIND: '/internal/inventory/result/find',
  INVENTORY_RESULT_CSV: '/internal/inventory/result/csv',
  RESET_INVENTORY: '/internal/inventory/reset',
  FORCE_UPDATE_INVENTORY: '/internal/inventory/forceupdate',
  ITEM_PICKING_GROUP_MOVE_LOCATION: '/internal/item/picking/group/move-location',
  ITEM: '/internal/item',
  ITEM_FIND: '/internal/item/find-web',
  ITEM_BULK_UPDATE: '/internal/item/bulk-update',
  ITEM_LOCATION: '/internal/item/location',
  ITEM_SHARED_WITH_SHOPS: '/internal/item/shared-with-shops',
  ITEM_DELETE: '/internal/item/delete',
  ITEM_MOVE_LOCATION_HISTORY_CSV: '/internal/item/move-location-history/csv',
  ITEM_MOVE_LOCATION_HISTORY_FIND: '/internal/item/move-location-history/find',
  ITEM_CSV_OUTPUT: '/internal/item/csv/output',
  ITEM_INVENTORY_LIST_BASED: '/internal/item/inventory/list-based',
  CSV_ORDER_PICKING_MOVE_ITEM_LOCATION: '/internal/csv/order-picking-for-move-item-location',
  CSV_REGISTER_LOCATION: '/internal/csv/register-location',
  CSV_SWITCH_SHARED_ITEM_WITH_SHOPS: '/internal/csv/switch-shared-item-with-shops',
  CSV_UPDATE_ITEM_SKU_WITH_BARCODE: '/internal/csv/update-item-sku-with-barcode',
  BUNDLE_ITEM: '/internal/bundle-item',
  BUNDLE_ITEM_FIND: '/internal/bundle-item/find',
  LOCATION_IN_WAREHOUSE: '/internal/location/in-warehouse',
  LOCATION_FIND: '/internal/location/find',
  LOCATION: '/internal/location',
  SHOP_ALL: '/internal/shop',
  SHOP_WAREHOUSE_TENANT: '/internal/shop/warehouse-tenant',
  SHOP_BASE_STORE: '/internal/shop/base-store',
  SHOP_BASE_STORE_KEYS: '/internal/shop/base-store/keys',
  SHOP_BASE_STORE_TOKENS: '/internal/shop/base-store/tokens',
  SYSTEM_LOCATION_ALL: '/internal/system-location/all',
  PACKING_STYLE_ALL: '/internal/packing-style/all',
  TRANSPORT_COMPANY_FIND: '/internal/transport-company/find',
  TRANSPORT_COMPANY_DELIVERABLE_TIME_ZONE: '/internal/transport-company-deliverable-time-zone',
  TRANSPORT_COMPANY_DELIVERY_TYPE: '/internal/transport-company-delivery-type',
  USER_AUTHORITY_GROUP_CODE: '/internal/user/authority-group-code',
  USER_ALL: '/internal/user/all',
  USER: '/internal/user',
  USER_SEARCH: '/internal/user/search',
  USER_CSV: '/internal/user/csv',
  WAREHOUSE_ZONE_IN_WAREHOUSE: '/internal/warehouse-zone/in-warehouse',
  WAREHOUSE_ZONE_ALL: '/internal/warehouse-zone/all',
  ITEM_SKU: '/internal/item-sku',
  ITEM_SKU_CSV: '/internal/item-sku/file/csv',
  ITEM_SKU_CSV_OUTPUT: '/internal/item-sku/csv/output',
  ITEM_SKU_CSV_TEMPLATE: '/internal/item-sku/file/csv/format',
  ITEM_RANK_FIND: '/internal/item-rank/find',
  WORKING_ITEM: '/internal/working-item',
  PICKING_REPLACEMENT: '/internal/shipping/picking/replacement',
  CANCEL_SHIPPING_ITEM: '/internal/shipping/cancel-shipping-item',
  PICKING_ITEM_FIND: '/internal/shipping/picking/history/item/find',
  PRINTER_SVF_LIST: '/internal/printer/svf/list',
  SHIPPING_LABEL: '/internal/shipping/shipping-label',
  SHIPPING_RETURN: '/internal/shipping/receive/result',
  RECEIVE_START: '/internal/receive/start',
  RECEIVE_COMPLETE: '/internal/receive/complete',
  RECEIVE: '/internal/receive/',
  RECEIVE_RETURN_FIND: '/internal/receive/return/find',
  RECEIVE_RETURN_CSV_OUTPUT: '/internal/receive/return/csv/output',
  SORTING_CODE_TASK: '/internal/sorting-code-task',
  STOCK_MOVING_LIST_FIND: '/internal/shipping/stock-moving-list',
  CANCEL_STOCK_MOVING: '/internal/shipping/stock-moving/cancel',
  RETURN_LOCATION_RELATION: '/internal/return-location-relation',
  MASTER_TABLE_SEARCH: '/internal/master-table/find',
  RANK_ITEM_DISTRIBUTION_SETTING_IMPORT_DATA: '/internal/item-rank/rank-item-distribution-setting/import-data',
  SERVICE_RANK_DISTRIBUTION_PRIORITY_IMPORT_DATA: '/internal/item-rank/service-rank-distribution-priority/import-data',
  RANK_SHOP_DISTRIBUTION_SETTING_IMPORT_DATA: '/internal/item-rank/rank-shop-distribution-setting/import-data',
  WAREHOUSE_DATA: '/internal/warehouse',
  AUTHORITY_GROUP: '/internal/authority-group',
  DATA_MAPPING: '/internal/data-mapping',
  SHOP_OF_USER: '/internal/shop/me',
};

export const makeURL = {
  getShippingOrder: (input: { shippingOrderCode: string }) => `${BRIDGE_API.SHIPPING_ORDER}/${input.shippingOrderCode}`,
  locationInWarehouse: (input: { warehouseCode: string }) =>
    `${BRIDGE_API.LOCATION_IN_WAREHOUSE}/${input.warehouseCode}`,
  shopWarehouseTenant: (input: { warehouseCode: string }) =>
    `${BRIDGE_API.SHOP_WAREHOUSE_TENANT}/${input.warehouseCode}`,
  warehouseZoneInWarehouse: (input: { warehouseCode: string }) =>
    `${BRIDGE_API.WAREHOUSE_ZONE_IN_WAREHOUSE}/${input.warehouseCode}`,
  findItemSku: (input: { warehouseCode: string }) =>
    `${BRIDGE_API.ITEM_SKU}/search/?warehouseCode_in=${input.warehouseCode}`,
  findItem: (input: { code: string }) => `${BRIDGE_API.ITEM}/${input.code}`,
  updateItem: (serial: string) => `${BRIDGE_API.ITEM}/${serial}`,
  allocationSearch: (input: { code: string }) => `${BRIDGE_API.SHIPPING_ALLOCATION_SEARCH}/${input.code}`,
  shippingReturn: (input: { shopIdentityCode: string; rfid: string; itemCode: string; shopCode: string }) =>
    `${BRIDGE_API.SHIPPING_RETURN}?shopIdentityCode=${input.shopIdentityCode}&shopCode=${input.shopCode}&rfid=${input.rfid}&itemCode=${input.itemCode}`,
  receiveRecordByCode: (input: { code: string }) => `${BRIDGE_API.RECEIVE}${input.code}`,
  returnLocationRelationByLocationId: (input: { locationId: number }) =>
    `${BRIDGE_API.RETURN_LOCATION_RELATION}/${input.locationId}/user`,
  modifyUser: (userId: number | string) => `${BRIDGE_API.USER}/${userId}`,
};

export default BRIDGE_API;
