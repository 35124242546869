import * as receiveReturnActions from './action';

export const CheckingBy = {
  Rfid: 'Rfid',
  ItemCode: 'ItemCode',
} as const;

export const CompleteMode = {
  Auto: 'Auto',
  Manual: 'Manual',
} as const;

export interface ReceiveReturnState {
  isOpenReceiveReturn: boolean;
  isReferencing: boolean;
  isWarning: boolean;
  readingRFIDs: string[];
  readRFIDs: string[];
  haveReadRFIDs: string[];
  checkingBy: string;
  completeMode: string;
}

export const initialState: ReceiveReturnState = {
  isOpenReceiveReturn: false,
  isReferencing: false,
  isWarning: false,
  readingRFIDs: [],
  readRFIDs: [],
  haveReadRFIDs: [],
  checkingBy: CheckingBy.Rfid,
  completeMode: CompleteMode.Auto,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case receiveReturnActions.SET_IS_REFERENCING:
      return {
        ...state,
        isReferencing: action.payload.isReferencing,
      };
    case receiveReturnActions.SET_IS_OPEN_RECEIVE_RETURN:
      return {
        ...state,
        isOpenReceiveReturn: action.payload.isOpenReceiveReturn,
      };
    case receiveReturnActions.SET_IS_WARNING:
      return {
        ...state,
        isWarning: action.payload.isWarning,
      };
    case receiveReturnActions.SET_HAVE_READ_RFIDS:
      return {
        ...state,
        haveReadRFIDs: action.payload.rfids,
      };
    case receiveReturnActions.ADD_HAVE_READ_RFID:
      return {
        ...state,
        haveReadRFIDs: [...state.haveReadRFIDs, action.payload.rfid],
      };
    case receiveReturnActions.SET_READING_RFID:
      return {
        ...state,
        readingRFIDs: action.payload.rfids,
      };
    case receiveReturnActions.ADD_READING_RFID:
      return {
        ...state,
        readingRFIDs: [...state.readingRFIDs, action.payload.rfid],
      };
    case receiveReturnActions.SET_READ_RFID:
      return {
        ...state,
        readRFIDs: action.payload.rfids,
      };
    case receiveReturnActions.SET_CHECKING_BY:
      return {
        ...state,
        checkingBy: action.payload.checkingBy,
      };
    case receiveReturnActions.SET_COMPLETE_MODE:
      return {
        ...state,
        completeMode: action.payload.completeMode,
      };
    default:
      return {
        ...state,
      };
  }
};
