import { applyMiddleware, combineReducers, createStore } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import MeReducer, { meStore } from './Me/reducer';
import ReceiveReturnReducer, { ReceiveReturnState } from './ReceiveReturn/reducer';
import UIAlertDialogReducer, { AlertDialogState } from './UI/AlertDialog/reducer';
import UIConfirmDialogReducer, { ConfirmDialogState } from './UI/ConfirmDialog/reducer';
import UIDrawerMenuReducer from './UI/DrawerMenu/reducer';
import UILoadingReducer from './UI/Loading/reducer';
import UINotificationReducer, { NotificationState } from './UI/Notification/reducer';
import { rootSaga } from './rootSaga';

export type Store = {
  notification: NotificationState;
  confirmDialog: ConfirmDialogState;
  alertDialog: AlertDialogState;
  receiveReturn: ReceiveReturnState;
  me: meStore;
};

const createRootReducer = () =>
  combineReducers({
    loading: UILoadingReducer,
    notification: UINotificationReducer,
    alertDialog: UIAlertDialogReducer,
    confirmDialog: UIConfirmDialogReducer,
    drawerMenu: UIDrawerMenuReducer,
    receiveReturn: ReceiveReturnReducer,
    me: MeReducer,
  });

const configureStore = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware, logger];

  const store = createStore(createRootReducer(), initialState, applyMiddleware(...middlewares));

  sagaMiddleware.run(rootSaga);

  return store;
};

const store = configureStore();

export default store;
