import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

type Props = {
  onRouteChangeStart?: (url: string) => void;
  onRouteChangeComplete?: (url: string) => void;
};

export const useRouteChange = ({ onRouteChangeStart = () => null, onRouteChangeComplete = () => null }: Props) => {
  const router = useRouter();
  const [isLoading, setLoading] = useState(false);

  const handleStart = (url) => {
    if (url !== router.pathname) {
      setLoading(true);
      onRouteChangeStart(url);
    }
  };
  const handleComplete = (url) => {
    if (url !== router.pathname) {
      setLoading(false);
      onRouteChangeComplete(url);
    }
  };
  const useRouteHandler = (deps = []) => {
    useEffect(() => {
      router.events.on('routeChangeStart', handleStart);
      router.events.on('routeChangeComplete', handleComplete);
      router.events.on('routeChangeError', handleComplete);
      return () => {
        router.events.off('routeChangeStart', handleStart);
        router.events.off('routeChangeComplete', handleComplete);
        router.events.off('routeChangeError', handleComplete);
      };
    }, deps);
  };

  return {
    isLoading,
    useRouteHandler,
  };
};
