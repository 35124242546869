export const OPEN_CONFIRM_DIALOG = Symbol('OPEN_CONFIRM_DIALOG');
export const OPEN_CONFIRM_DIALOGS = Symbol('OPEN_CONFIRM_DIALOGS');
export const CLOSE_CONFIRM_DIALOG = Symbol('CLOSE_CONFIRM_DIALOG');
export const SUBMIT_CONFIRM_DIALOG = Symbol('SUBMIT_CONFIRM_DIALOG');
export const UPDATE_CONFIRM_DIALOG = Symbol('UPDATE_CONFIRM_DIALOG');

type ConfirmDialogPayload = {
  message: string;
  submitButtonLabel?: string;
  closeButtonLabel?: string;
  openAfterSubmit?: boolean;
  onSubmit?: () => void;
  afterClose?: () => Promise<void> | void;
};
export type ConfirmDialogsPayload = {
  dialogDataList: {
    message: string;
    submitButtonLabel?: string;
    closeButtonLabel?: string;
  }[];
  openAfterSubmit?: boolean;
  onSubmit?: () => void;
};

export const openConfirmDialog = (payload: ConfirmDialogPayload) => ({
  type: OPEN_CONFIRM_DIALOG,
  payload,
});

export const openConfirmDialogs = (payload: ConfirmDialogsPayload) => ({
  type: OPEN_CONFIRM_DIALOGS,
  payload,
});

export const closeConfirmDialog = () => ({
  type: CLOSE_CONFIRM_DIALOG,
});

export const submitConfirmDialog = () => ({
  type: SUBMIT_CONFIRM_DIALOG,
});

export const updateConfirmDialog = (payload: {
  message: string;
  submitButtonLabel?: string;
  closeButtonLabel?: string;
  openAfterSubmit?: boolean;
  onSubmit?: () => void;
}) => ({
  type: UPDATE_CONFIRM_DIALOG,
  payload,
});
