import useDidMount from '@rooks/use-did-mount';
import * as React from 'react';
import { useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AbilityContext } from './Can';
import defineAbilityFor from './Permission';
import AlertDialog from './components/AlertDialog';
import ConfirmDialog from './components/ConfirmDialog';
import Header from './components/Header';
import Loading from './components/Loading';
import Snackbar from './components/Snackbar';
import { useRouteChange } from './hooks';
import { Store } from './redux';
import * as MeAction from './redux/Me/action';
import * as ReceiveReturnAction from './redux/ReceiveReturn/action';
import * as AlertDialogAction from './redux/UI/AlertDialog/action';
import { AlertDialogState } from './redux/UI/AlertDialog/reducer';
import * as ConfirmDialogAction from './redux/UI/ConfirmDialog/action';
import { ConfirmDialogState } from './redux/UI/ConfirmDialog/reducer';
import { DrawerMenuState } from './redux/UI/DrawerMenu/reducer';
import * as LoadingAction from './redux/UI/Loading/action';
import { LoadingState } from './redux/UI/Loading/reducer';
import * as NotificationAction from './redux/UI/Notification/action';
import { NotificationState } from './redux/UI/Notification/reducer';
import styles from './styles/common.module.css';

interface Props {
  loading: LoadingState;
  notification: NotificationState;
  alertDialog: AlertDialogState;
  confirmDialog: ConfirmDialogState;
  drawerMenu: DrawerMenuState;
  me: FixType;
  loadingAction: typeof LoadingAction;
  notificationAction: typeof NotificationAction;
  alertDialogAction: typeof AlertDialogAction;
  confirmDialogAction: typeof ConfirmDialogAction;
  meAction: typeof MeAction;
  receiveReturnAction: typeof ReceiveReturnAction;
}

const App: React.FC<Props> = (props) => {
  const {
    loading,
    notification,
    alertDialog,
    confirmDialog,
    drawerMenu,
    me,
    notificationAction,
    alertDialogAction,
    loadingAction,
    confirmDialogAction,
    meAction,
    children,
  } = props;

  const onRouteChangeStart = useCallback((nextPath: string) => {
    loadingAction.showLoading();
    const ignorePaths = ['/auth/login'];
    if (ignorePaths.includes(nextPath)) {
      return;
    }
    props.meAction.checkAuth();
  }, []);

  const onRouteChangeComplete = useCallback((_) => {
    loadingAction.hideLoading();
  }, []);

  const { useRouteHandler } = useRouteChange({
    onRouteChangeStart,
    onRouteChangeComplete,
  });
  useRouteHandler();

  useDidMount(() => {
    // Reload時の自動ログインチェッカー
    props.meAction.checkAuth();

    props.receiveReturnAction.initReceiveReturnSocket();
  });

  // AbilityContextの作成
  const meInfo = useSelector((state: Store) => state.me);

  const ability = defineAbilityFor({
    screenPermission: meInfo.screenPermissions,
    clickableComponentPermission: meInfo.clickableComponentPermission,
    editableComponentPermission: meInfo.editableComponentPermission,
    viewableComponentPermission: meInfo.viewableComponentPermission,
  });

  return (
    <AbilityContext.Provider value={ability}>
      <Loading isLoading={loading.isLoading} />
      <Snackbar
        show={notification.show}
        onClose={() => notificationAction.closeNotification()}
        message={notification.message}
        keep={notification.keep}
        messageBarType={notification.messageBarType}
      />
      <ConfirmDialog
        open={confirmDialog.open}
        openAfterSubmit={confirmDialog.openAfterSubmit}
        message={confirmDialog.message}
        submitButtonLabel={confirmDialog.submitButtonLabel}
        closeButtonLabel={confirmDialog.closeButtonLabel}
        onClose={() => {
          confirmDialogAction.closeConfirmDialog();

          if (confirmDialog.afterClose) {
            confirmDialog.afterClose();
          }
        }}
        onSubmit={confirmDialog.onSubmit}
      />
      <AlertDialog
        open={alertDialog.open}
        onClose={() => {
          alertDialogAction.closeAlertDialog();
          if (alertDialog.afterClose) {
            alertDialog.afterClose();
          }
        }}
        message={alertDialog.message}
        isBackdropClosed={alertDialog.isBackdropClosed}
        alertType={alertDialog.alertType}
      />
      <Header meAction={meAction} me={me} />
      <div className={drawerMenu.open ? `${styles.children} ${styles.childrenShift}` : `${styles.children}`}>
        {children}
      </div>
    </AbilityContext.Provider>
  );
};

function mapStateToProps(state: FixType) {
  const { loading, notification, alertDialog, confirmDialog, me, drawerMenu } = state;

  return { loading, notification, alertDialog, confirmDialog, me, drawerMenu };
}

function mapDispatchToProps(dispatch: FixType) {
  return {
    loadingAction: bindActionCreators(LoadingAction as FixType, dispatch),
    notificationAction: bindActionCreators(NotificationAction as FixType, dispatch),
    alertDialogAction: bindActionCreators(AlertDialogAction as FixType, dispatch),
    confirmDialogAction: bindActionCreators(ConfirmDialogAction as FixType, dispatch),
    receiveReturnAction: bindActionCreators(ReceiveReturnAction as FixType, dispatch),
    meAction: bindActionCreators(MeAction as FixType, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
