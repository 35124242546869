import { Typography } from '@material-ui/core';
import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiSnackbarContent from '@material-ui/core/SnackbarContent';
import * as React from 'react';

import { NotificationState } from '../../redux/UI/Notification/reducer';
import styles from './style.module.css';

interface Props extends NotificationState {
  onClose: () => void;
}

class Snackbar extends React.Component<Props> {
  render() {
    const { show, message, messageBarType, onClose, keep } = this.props;

    return (
      <MuiSnackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={show}
        onClose={onClose}
        autoHideDuration={keep ? null : 5000}
        className={styles.container}
      >
        <MuiSnackbarContent
          className={styles[messageBarType]}
          action={
            <Typography variant="button" className={styles.close} onClick={onClose}>
              閉じる
            </Typography>
          }
          message={
            Array.isArray(message) ? (
              message.map((elem) => (
                <span key={`snackBar-${elem}`} className={styles.message}>
                  {elem}
                </span>
              ))
            ) : (
              <span className={styles.message}>{message}</span>
            )
          }
        />
      </MuiSnackbar>
    );
  }
}

export default Snackbar;
