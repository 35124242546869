import React from 'react';

import styles from './style.module.css';

interface Props {
  className?: string;
}

const AttentionMark: React.FC<Props> = (props: Props) => (
  <div className={styles.container + ' ' + (props.className ? props.className : '')}>!</div>
);

export default AttentionMark;
