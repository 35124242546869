import Router from 'next/router';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MeModel from '../../model/Me';
import * as MeAction from '../../redux/Me/action';
import * as DrawerMenuAction from '../../redux/UI/DrawerMenu/action';
import MiniDrawer from './Drawer';
import styles from './style.module.css';

interface Props {
  me: MeModel;
  drawerMenu: { open: boolean };
  meAction: typeof MeAction;
  drawerMenuAction: typeof DrawerMenuAction;
}
interface State {
  isOpen: boolean;
  anchorEl: React.ElementRef<FixType>;
  currentUrl: string;
}

class Header extends React.Component<Props, State> {
  state = {
    isOpen: false,
    anchorEl: null,
    currentUrl: '',
  };

  handleRouteChange = (url) => {
    this.setState({
      currentUrl: url,
    });
  };

  componentDidMount() {
    Router.events.on('routeChangeComplete', this.handleRouteChange);
  }

  componentWillUnmount() {
    Router.events.off('routeChangeComplete', this.handleRouteChange);
  }

  handleOpen = (event: FixType) => {
    this.setState({
      isOpen: true,
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
      anchorEl: null,
    });
  };

  logout = () => {
    this.setState({
      isOpen: false,
    });

    this.props.meAction.logout();
  };

  isLoggedIn = () => {
    return this.props.me.isLoggedIn;
  };

  render() {
    return (
      <div className={styles.header}>
        {this.isLoggedIn() && (
          <MiniDrawer
            isOpen={this.props.drawerMenu.open}
            open={this.props.drawerMenuAction.openDrawerMenu}
            close={this.props.drawerMenuAction.closeDrawerMenu}
            logout={this.logout}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { drawerMenu } = state;

  return { drawerMenu };
}

function mapDispatchToProps(dispatch) {
  return {
    drawerMenuAction: bindActionCreators(DrawerMenuAction as FixType, dispatch),
  };
}

// export default Header
export default connect(mapStateToProps, mapDispatchToProps)(Header);
